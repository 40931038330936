import clsx from "clsx"
import React, { useState, useCallback } from "react"
import { useLocation, useNavigate } from "react-router-dom"

import { Icon } from "@components/ui/Icon/Icon"
import { Option } from "@framework/types/utils"
import Button from "@components/ui/Button/Button"
import useNavigationScope from "@components/layout/NavigationScopeContext/useNavigationScope"

import styles from "./NavItem.module.sass"

export interface NavItemProps {
  active: boolean
  isSubMenu?: boolean
  isRoot?: boolean
  option: Option
  parentOption: Option
  setSelectedOption: (option: Option) => void
  setSideBarOpen: (boolean: boolean) => void
}
const getFirstPathSegment = (path: string) => path.split("/")?.[1]

export const checkHomePageActive = (path: string) => {
  const firstPath = getFirstPathSegment(path)
  return firstPath === "solutions"
}

const ExpandableNavItem: React.FC<NavItemProps> = ({
  option,
  isSubMenu,
  parentOption,
  setSelectedOption,
  setSideBarOpen,
  isRoot,
}) => {
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  const { activeOption, setActiveOption } = useNavigationScope()
  const location = useLocation()

  const isHomePageActive: Boolean =
    option.name === "/home" && checkHomePageActive(location.pathname)

  const isActive = React.useMemo(() => {
    const currentPathSegment = getFirstPathSegment(location.pathname)
    return (
      getFirstPathSegment(option.name) === currentPathSegment ||
      option?.subOptions?.some(
        (item) => getFirstPathSegment(item.name) === currentPathSegment
      )
    )
  }, [location.pathname, option])

  const handleOpen = useCallback(
    (e: React.MouseEvent) => {
      setOpen((prevOpen) => !prevOpen)
      setSelectedOption(option)
      setActiveOption(parentOption)
      e.stopPropagation()
    },
    [option, setSelectedOption]
  )

  const handleNav = useCallback(
    (e: React.MouseEvent) => {
      if (option.name && !option.subOptions?.length) {
        setActiveOption(parentOption)
        navigate(option.name)
      } else {
        handleOpen(e)
      }
    },
    [navigate, option.name]
  )

  const handleSubNav = useCallback(
    (subNav: Option) => {
      setSelectedOption(option)
      setSideBarOpen(false)
      navigate(subNav.name)
    },
    [navigate, option, setSelectedOption, setSideBarOpen]
  )

  const isParentActiveCondition = React.useMemo(() => {
    return parentOption === option && isActive
  }, [activeOption, option, open, parentOption, isActive])

  return (
    <>
      <div
        onClick={handleNav}
        className={clsx(styles.expandMenuItem, {
          [styles.active]: isHomePageActive || isParentActiveCondition,
          [styles.childActive]: open,
          [styles.withAnimation]: !isRoot,
        })}
        role="button"
        onKeyPress={(e) => {
          if (e.key === "Enter") handleNav(e as unknown as React.MouseEvent)
        }}
        tabIndex={0}
      >
        <span>
          {!isSubMenu && (
            <Icon
              className={clsx(styles.optionIcon, {
                [styles.activeIcon]: isParentActiveCondition,
              })}
              name={option.icon}
            />
          )}
          <span className={clsx({ [styles.subOptionHeader]: isSubMenu })}>
            {option.value}
          </span>
        </span>
        {option.subOptions && (
          <Button variant="text">
            <Icon
              name="line-arrow-down"
              rotateAngle={open ? 180 : 0}
              onClick={handleOpen}
            />
          </Button>
        )}
      </div>
      {open && (
        <div
          className={clsx(
            styles.subOptionWrapper,
            isRoot ? styles.paddingLeft : styles.paddingLeftSmall,
            { [styles.paddingTop]: !isRoot }
          )}
        >
          {option.subOptions?.map((item, index) =>
            (item?.subOptions?.length ?? 0) > 0 ? (
              <ExpandableNavItem
                active={false}
                option={item}
                parentOption={parentOption}
                isSubMenu
                setSelectedOption={setSelectedOption}
                setSideBarOpen={setSideBarOpen}
              />
            ) : (
              <div
                className={clsx(
                  styles.subOption,
                  isRoot ? styles.paddingLeftSmall : null
                )}
                role="button"
                onKeyPress={(e) => {
                  if (e.key === "Enter") handleSubNav(item)
                }}
                tabIndex={0}
                onClick={() => handleSubNav(item)}
                key={item.value}
              >
                {item?.value}
              </div>
            )
          )}
        </div>
      )}
    </>
  )
}

export default React.memo(ExpandableNavItem)
