import clsx from "clsx"
import { observer } from "mobx-react-lite"
import React, { useEffect } from "react"
import { Outlet, useLocation, useNavigate } from "react-router-dom"

import MainLayout from "@components/layout/MainLayout/MainLayout"
import { useController, useStore } from "@store/index"
import { DONT_SHOW_INTRO_AGAIN_STORAGE_KEY } from "@framework/constants/auth"
import AvatarPickerSidebar from "@components/prototypes/AvatarPickerSidebar"
import Button from "@components/ui/Button/Button"
import SOLUTION_CATEGORY from "@framework/constants/solution-category"
import { getAvailableCategories } from "@utils/product-solution"
import mainRoutes from "@root/main.routes"
import { SolutionCategory } from "@framework/types/solution"
import Text from "@components/ui/Typography/Text"
import NotFound from "@components/ui/NotFound/NotFound"
import Loader from "@components/ui/Loader/BarLoader"

import useAvatarScope from "./AvatarScopeContext/useAvatarScope"
import AvailableSolutions from "./AvailableSolutions/AvailableSolutions"
import Intro from "./Intro/Intro"
import HomeInfo from "./HomeInfo/HomeInfo"

import styles from "./HomeBeta.module.sass"

const DEFAULT_WELCOME_MESSAGE = "What's your focus today?"

const HomePageBeta: React.FC = observer(() => {
  const {
    userStore: { user },
    appStore: { introShown, hideIntro },
    restrictionsStore,
    solutionsStore,
  } = useStore()

  const navigate = useNavigate()

  const { category, solutions, isLoading } = solutionsStore

  const { solutionsController } = useController()

  const { avatar, setAvatar } = useAvatarScope()

  const greeting = `Welcome${user ? `, ${user.firstName}!` : ""}`

  const dontShowAgain = JSON.parse(
    localStorage.getItem(DONT_SHOW_INTRO_AGAIN_STORAGE_KEY) ?? "false"
  )

  const categories = getAvailableCategories(SOLUTION_CATEGORY, solutions || [])
  const location = useLocation()

  useEffect(() => {
    if (!category) {
      solutionsController.setCategory(SOLUTION_CATEGORY[0])
    }
  }, [category, solutionsController])

  useEffect(() => {
    const categoryNameFromPath = new URLSearchParams(location.search).get(
      "category"
    )
    if (
      categoryNameFromPath &&
      categoryNameFromPath?.toLowerCase() !== category?.category.toLowerCase()
    ) {
      const categoryFromPath = categories.find(
        (c) => c.category.toLowerCase() === categoryNameFromPath.toLowerCase()
      )

      solutionsController.setCategory(categoryFromPath || categories[0])

      if (!categoryFromPath) {
        navigate(mainRoutes.home())
      }
    }
  }, [location.search, category, solutionsController, SOLUTION_CATEGORY])

  useEffect(() => {
    if (avatar?.id) {
      solutionsController.loadAvatarSolutions(avatar.id)
    }
  }, [avatar?.id])

  if (restrictionsStore.showIntro && !dontShowAgain && introShown) {
    return <Intro onHide={hideIntro} />
  }

  const handleNavigateToCategory = (item: SolutionCategory) => {
    navigate(`${mainRoutes.home()}?category=${item.category.toLowerCase()}`)
  }

  return (
    <MainLayout>
      <div className={clsx(styles.root)}>
        <header className={styles.header}>
          <div className={styles.greeting}>
            <Text variant="h1" weight="bold">
              {greeting}
            </Text>
            <Text variant="h5">{DEFAULT_WELCOME_MESSAGE}</Text>
          </div>

          <AvatarPickerSidebar value={avatar?.id} onChange={setAvatar} />
        </header>
        <div className={styles.content}>
          <div className={styles.mainSection}>
            {categories.length > 1 && (
              <div className={styles.categories}>
                {categories.map((item) => (
                  <Button
                    key={item.category}
                    color={
                      item.category === category?.category
                        ? "primary"
                        : "secondary"
                    }
                    size="medium"
                    variant="contained"
                    onClick={() => handleNavigateToCategory(item)}
                  >
                    {item.category}
                  </Button>
                ))}
              </div>
            )}

            {isLoading ? (
              <div className={styles.placeholderWrapper}>
                <Loader size="huge" fluid />
              </div>
            ) : solutions?.length ? (
              <AvailableSolutions />
            ) : (
              <div className={styles.placeholderWrapper}>
                <NotFound />
              </div>
            )}
          </div>

          <div className={clsx(styles.sidebarSection)}>
            <HomeInfo />
          </div>
        </div>
      </div>

      <Outlet />
    </MainLayout>
  )
})

export default HomePageBeta
