import { observer } from "mobx-react-lite"
import React, { useMemo, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import clsx from "clsx"

import Icon from "@components/ui/Icon/Icon"
import { Option } from "@framework/types/utils"
import { useStore } from "@store/index"
import { useAppConfig } from "@components/theme/AppConfigProvider"
import Logo from "@components/prototypes/Logo/Logo"
import Button from "@components/ui/Button/Button"
import useNavigationScope from "@components/layout/NavigationScopeContext/useNavigationScope"
import Text from "@components/ui/Typography/Text"
import useClickOutside from "@components/hooks/useOutsideClick"

import UserSettingsMenu from "./UserSettingsMenu"
import UIThemeMenu from "./UIThemeMenu"
import NavIcon from "./NavItem/NavIcon"
import ExpandableNavItem from "./NavItem/ExandableNavItem"

import styles from "./Sidebar.module.sass"

type NavItemBadgeProps = {
  name: string
}
let leaveTimeout: NodeJS.Timeout | null = null

const SIDEBAR_AUTO_CLOSE_TIME_MILLIS = 1200

const NavItemBadge: React.FC<NavItemBadgeProps> = observer(
  ({ name }) =>
    // const {
    //   updateStore: { hasChanges },
    // } = useStore()
    // if (name === "/updates" && hasChanges)
    //   return <NotificationBadge className={styles.loader} />
    null
)

export interface SidebarProps {}

export const Sidebar: React.FC<SidebarProps> = observer(() => {
  const {
    allowedNavOptions: menuOptions,
    allowedProfileOptions: profileOptions,
  } = useNavigationScope()
  const theme = useAppConfig()
  const [selectedOption, setSelectedOption] = useState<Option | null>()
  const { restrictionsStore: access } = useStore()
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  const containerRef = React.useRef<HTMLDivElement | null>(null)

  useClickOutside({
    element: containerRef,
    disabled: !open,
    options: true,
    callBack: () => setOpen(false),
  })

  const handleMouseLeave = () => {
    if (leaveTimeout) {
      clearTimeout(leaveTimeout)
    }
    leaveTimeout = setTimeout(() => {
      setOpen(false)
    }, SIDEBAR_AUTO_CLOSE_TIME_MILLIS)
  }

  const handleMouseEnter = () => {
    if (leaveTimeout) {
      clearTimeout(leaveTimeout)
    }
  }

  const memoizedMenuOptions = useMemo(
    () =>
      menuOptions.map((item) =>
        open ? (
          <ExpandableNavItem
            active={item === selectedOption}
            key={item.name}
            isRoot
            option={item}
            parentOption={item}
            setSelectedOption={setSelectedOption}
            setSideBarOpen={setOpen}
          />
        ) : (
          <NavIcon
            key={item.name}
            option={item}
            name={item.name}
            icon={item.icon}
            onClick={() => navigate(item.name)}
            className={styles.navItem}
            mark={<NavItemBadge name={item.name} />}
          />
        )
      ),
    [menuOptions, open, selectedOption]
  )

  const isNesh = theme.copy.logo === "NESH"
  return (
    <div
      className={clsx(open ? styles.rootOpen : styles.rootClose)}
      onMouseLeave={handleMouseLeave}
      onMouseEnter={handleMouseEnter}
      ref={containerRef}
    >
      <div className={styles.hoverStrip} onMouseEnter={() => setOpen(true)} />
      <div className={styles.header}>
        {open ? (
          <div className={styles.logoOpen}>
            <Link to="/home">
              <Logo name={theme.copy.logo} showLogo />
              <Text variant="h4" className={styles.name}>
                {isNesh ? "NESH" : ""}
              </Text>
            </Link>
            <Button size="tiny" variant="text" onClick={() => setOpen(false)}>
              <Icon name="cross" rotateAngle={-90} />
            </Button>
          </div>
        ) : (
          <div
            role="button"
            tabIndex={0}
            className={clsx(styles.logoClose, { [styles.paddingLeft]: isNesh })}
            onClick={() => navigate("/home")}
            onKeyPress={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                setOpen(true)
              }
            }}
          >
            <Logo name={theme.copy.logo} showLogo />
          </div>
        )}
      </div>
      {open ? (
        <div className={styles.expandableMenu}>{memoizedMenuOptions}</div>
      ) : (
        <div className={styles.menu}>{memoizedMenuOptions}</div>
      )}

      <div className={styles.footer}>
        {access.canSwitchTheme && <UIThemeMenu showText={open} />}

        <a
          href={theme.support.supportPageURL}
          target="_blank"
          className={styles.support}
          rel="noreferrer"
        >
          <Icon className={styles.helpButton} name="question-mark" />
          {open && (
            <Text className={styles.name} variant="body2" color="inherit">
              Help Me
            </Text>
          )}
        </a>

        <UserSettingsMenu showMore={open} menuOptions={profileOptions} />
      </div>
    </div>
  )
})

export default Sidebar
