import React from "react"

import styles from "./Logo.module.sass"

export interface LogoProps extends React.HTMLProps<HTMLElement> {
  name?: string // "NESH" | "ASKIEEE" | other
  showLogo?: boolean
}

export const Logo: React.FC<LogoProps> = ({ name = "NESH", showLogo }) => {
  if (name === "NESH")
    return showLogo ? (
      <img
        src="/images/nesh_icon.svg"
        alt="Logo icon"
        className={styles.neshIcon}
      />
    ) : (
      <b>NESH</b>
    )
  if (name === "ASKIEEE")
    return (
      <span>
        <span className={styles.italic}>Ask</span>
        <b>IEEE</b>
      </span>
    )

  return <>{name}</>
}

export default Logo
