import React, { useEffect } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import Skeleton from "@components/ui/Skeleton/Skeleton"
import Text from "@components/ui/Typography/Text"
import Button from "@components/ui/Button/Button"
import Icon from "@components/ui/Icon/Icon"
import Tooltip from "@components/ui/Tooltip/Tooltip"
import TooltipContainer from "@components/ui/Tooltip/TooltipContainer"
import { useController, useStore } from "@store/index"
import useActiveAvatar from "@pages/search/ActiveAvatarContext/useActiveAvatar"

import styles from "./RecentQuestions.module.sass"

type RecentQuestionsProps = {
  className?: string
}

const RecentQuestions: React.FC<RecentQuestionsProps> = observer(
  ({ className }) => {
    const {
      searchSuggestionStore: {
        favoriteQuestions,
        isQuestionsLoading,
        loadQuestions,
      },
      solutionsStore: { solution },
    } = useStore()
    const { avatar } = useActiveAvatar()

    const { factFinderSolutionController } = useController()

    const handleClick = (query: string) => {
      if (solution?.id)
        factFinderSolutionController.search(query, avatar, solution.id)
    }

    useEffect(() => {
      if (avatar && solution?.id) {
        loadQuestions(avatar.id, solution.id)
      }
    }, [avatar, solution?.id])

    if (favoriteQuestions.length < 1) return null
    return (
      <div className={clsx(styles.root, className)}>
        <Text variant="h3" weight="bold" align="center">
          Recent Questions
        </Text>
        <div className={styles.questions}>
          {isQuestionsLoading && (
            <Skeleton
              count={5}
              maxWidth={1000}
              lineHeight={24}
              spacing={16}
              rounded
              className={styles.skeletons}
            />
          )}
          {favoriteQuestions.map((question) => (
            <Tooltip
              key={question.name}
              content={
                <TooltipContainer
                  placement="bottom"
                  className={styles.tooltip}
                  color="primary"
                >
                  <Text variant="caption1">{question.value}</Text>
                </TooltipContainer>
              }
              mode="onHover"
            >
              <Button
                variant="outlined"
                size="small"
                before={<Icon name="timer" />}
                className={styles.question}
                onClick={() => handleClick(question.value)}
              >
                <Text
                  variant="h6"
                  className={styles.questionOverflow}
                  color="text70Color"
                >
                  {question.value}
                </Text>
              </Button>
            </Tooltip>
          ))}
        </div>
      </div>
    )
  }
)

export default RecentQuestions
