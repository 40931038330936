import { observer } from "mobx-react-lite"
import React from "react"

import MainLayout from "@components/layout/MainLayout/MainLayout"
import IconChip from "@components/ui/Chip/IconChip"
import Container from "@components/ui/Container/Container"
import Icon from "@components/ui/Icon/Icon"
import LinkCard from "@components/ui/LinkCard/LinkCard"
import { useStore } from "@store/index"
import mainRoutes from "@root/main.routes"

import styles from "./ExpertsMenuPage.module.sass"

export interface AdminPanelMenuProps {}

export const ExpertsMenuPage: React.FC<AdminPanelMenuProps> = observer(() => {
  const { restrictionsStore: access } = useStore()

  return (
    <MainLayout noPadding>
      <Container>
        <div className={styles.root}>
          <div className={styles.header}>
            <h2>Experts</h2>
          </div>
          <div className={styles.body}>
            <LinkCard
              to={mainRoutes.experts()}
              label="Leaderboard"
              description="Experts Leaderboard"
              icon={
                <IconChip color="primary">
                  <Icon name="users" />
                </IconChip>
              }
            />
            {access.isExpertInsightsAvailable && (
              <LinkCard
                to={mainRoutes.expertInsights()}
                label="Insights"
                description="Experts insights"
                icon={
                  <IconChip color="primary">
                    <Icon name="user-insight" />
                  </IconChip>
                }
              />
            )}

            {access.isQuestionsSectionAvailable && (
              <LinkCard
                to={mainRoutes.questions()}
                label="Questions"
                description="Questions ask to experts"
                icon={
                  <IconChip color="primary">
                    <Icon name="questions-chat" />
                  </IconChip>
                }
              />
            )}
          </div>
        </div>
      </Container>
    </MainLayout>
  )
})

export default ExpertsMenuPage
